import { createAction, props } from '@ngrx/store';

export namespace FeatureFlagActions {
  export const loadFeatureFlags = createAction(
    '[FEATURE FLAG] LOAD_FEATURE_FLAGS',
  );

  export const loadShowMessageSuccess = createAction(
    '[FEATURE FLAG] LOAD_SHOW_INFO_MESSAGE_SUCCESS',
    props<{ payload: boolean }>(),
  );
  export const loadShowMessageError = createAction(
    '[FEATURE FLAG] LOAD_SHOW_INFO_MESSAGE_ERROR',
  );

  export const loadSignalRSuccess = createAction(
    '[FEATURE FLAG] LOAD_SIGNAL_R_SUCCESS',
    props<{ payload: boolean }>(),
  );
  export const loadSignalRError = createAction(
    '[FEATURE FLAG] LOAD_SIGNAL_R_ERROR',
  );
}
