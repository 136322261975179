import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {
  AlertService,
  DashboardService,
} from '@connected/data-access/fleet-service';
import { Observable, switchMap, map, catchError } from 'rxjs';
import { StatusCodesActions } from './status-codes.actions';

const DATE_FORMAT = 'YYYY-MM-DD';
@Injectable({
  providedIn: 'root',
})
export class StatusCodesEffects {
  private _actions$ = inject(Actions);

  loadDashboardStatusCodes$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(StatusCodesActions.LoadDashboardStatusCodes),
      switchMap(({ payload }) =>
        this._dashboardService.getDashboardAlertCount(payload).pipe(
          map((data) =>
            StatusCodesActions.LoadDashboardStatusCodesSuccess({
              payload: data,
            }),
          ),
          catchError(() => [
            StatusCodesActions.LoadDashboardStatusCodesError(),
          ]),
        ),
      ),
    ),
  );

  loadHistory$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(StatusCodesActions.LoadHistory),
      switchMap(({ payload }) =>
        this._alertsService
          .getHistoryAlertsPerEquipmentNumber(
            payload.equipmentNumber,
            payload.from || undefined,
            payload.to || undefined,
            payload.pageIndex,
            payload.pageSize,
            payload.type || undefined,
            payload.code || undefined,
            payload.activeSort,
            payload.sortDirection.length
              ? payload.sortDirection.toString().toUpperCase()
              : undefined,
          )
          .pipe(
            map((data) =>
              StatusCodesActions.LoadHistorySuccess({ payload: data }),
            ),
            catchError(() => [StatusCodesActions.LoadHistoryError()]),
          ),
      ),
    ),
  );

  loadStatistics$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(StatusCodesActions.LoadStatistics),
      switchMap(({ payload }) =>
        this._alertsService
          .getAlertsStatisticsPerEquipmentNumber(
            payload.equipmentNumber,
            payload.fromDate?.format(DATE_FORMAT) || undefined,
            payload.toDate?.format(DATE_FORMAT) || undefined,
            payload.page,
            payload.pageSize,
            payload.sortField || undefined,
            payload.sortOrder || undefined,
          )
          .pipe(
            map((data) =>
              StatusCodesActions.LoadStatisticsSuccess({
                payload: data,
              }),
            ),
            catchError(() => [StatusCodesActions.LoadStatisticsError()]),
          ),
      ),
    ),
  );

  loadOverview$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(StatusCodesActions.LoadOverview),
      switchMap(() =>
        this._alertsService.getAlerts().pipe(
          map((data) =>
            StatusCodesActions.LoadOverviewSuccess({ payload: data }),
          ),
          catchError(() => [StatusCodesActions.LoadOverviewError()]),
        ),
      ),
    ),
  );

  constructor(
    private readonly _dashboardService: DashboardService,
    private readonly _alertsService: AlertService,
  ) {}
}
