import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Injectable, inject } from '@angular/core';
import { FeatureService } from '@connected/data-access/fleet-service';
import { FeatureFlagActions } from './feature-flag.actions';

@Injectable()
export class FeatureFlagEffects {
  private _actions$ = inject(Actions);

  loadShowInfoMessage$ = createEffect(() =>
    this._actions$.pipe(
      ofType(FeatureFlagActions.loadFeatureFlags),
      switchMap(() =>
        this._featureService.getFeature('SHOW_INFO_MESSAGE').pipe(
          map((data) =>
            FeatureFlagActions.loadShowMessageSuccess({
              payload: data,
            }),
          ),
          catchError(() => [FeatureFlagActions.loadShowMessageError()]),
        ),
      ),
    ),
  );

  loadSignalRFeature$ = createEffect(() =>
    this._actions$.pipe(
      ofType(FeatureFlagActions.loadFeatureFlags),
      switchMap(() =>
        this._featureService.getFeature('IS_SIGNAL_R_ENABLED').pipe(
          map((data) =>
            FeatureFlagActions.loadSignalRSuccess({
              payload: data,
            }),
          ),
          catchError(() => [FeatureFlagActions.loadSignalRError()]),
        ),
      ),
    ),
  );

  constructor(private _featureService: FeatureService) {}
}
