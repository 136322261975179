import {
  GetAlertCountCommandResult,
  GetAlertsCommandResult,
  GetAlertsStatisticsPerEquipmentNumberCommandResult,
  GetHistoryAlertsPerEquipmentCommandResult,
} from '@connected/fleet-service/models';
import { createAction, props } from '@ngrx/store';
import {
  AlertHistoryQuery,
  AlertStatisticQuery,
  StatusCodesFilterSetup,
} from '../models';
import { Sort } from '@angular/material/sort';

export namespace StatusCodesActions {
  export const LoadDashboardStatusCodes = createAction(
    '[StatusCodes] LOAD_DASHBOARD_STATUS_CODES',
    props<{ payload: number | undefined }>(),
  );
  export const LoadDashboardStatusCodesSuccess = createAction(
    '[StatusCodes] LOAD_DASHBOARD_STATUS_CODES_SUCCESS',
    props<{ payload: GetAlertCountCommandResult }>(),
  );
  export const LoadDashboardStatusCodesError = createAction(
    '[StatusCodes] LOAD_DASHBOARD_STATUS_CODES_ERROR',
  );

  export const SetFilter = createAction(
    '[StatusCodes] SET_STATUS_CODES_FILTER',
    props<{
      payload: StatusCodesFilterSetup;
    }>(),
  );

  export const LoadHistory = createAction(
    '[StatusCodes] LOAD_HISTORY',
    props<{ payload: AlertHistoryQuery }>(),
  );
  export const LoadHistorySuccess = createAction(
    '[StatusCodes] LOAD_HISTORY_SUCCESS',
    props<{
      payload: GetHistoryAlertsPerEquipmentCommandResult;
    }>(),
  );
  export const LoadHistoryError = createAction(
    '[StatusCodes] LOAD_HISTORY_ERROR',
  );
  export const ClearHistory = createAction('[StatusCodes] CLEAR_HISTORY');

  export const LoadStatistics = createAction(
    '[StatusCodes] LOAD_STATISTICS',
    props<{ payload: AlertStatisticQuery }>(),
  );
  export const LoadStatisticsSuccess = createAction(
    '[StatusCodes] LOAD_STATISTICS_SUCCESS',
    props<{
      payload: GetAlertsStatisticsPerEquipmentNumberCommandResult;
    }>(),
  );
  export const LoadStatisticsError = createAction(
    '[StatusCodes] LOAD_STATISTICS_ERROR',
  );
  export const ClearStatistics = createAction('[StatusCodes] CLEAR_STATISTICS');

  export const LoadOverview = createAction('[StatusCodes] LOAD_OVERVIEW');
  export const LoadOverviewSuccess = createAction(
    '[StatusCodes] LOAD__OVERVIEW_SUCCESS',
    props<{ payload: GetAlertsCommandResult }>(),
  );
  export const LoadOverviewError = createAction(
    '[StatusCodes] LOAD_OVERVIEW_ERROR',
  );
  export const saveSort = createAction(
    '[StatusCodes] SAVE_SORT_OVERVIEW',
    props<{ payload: Sort }>(),
  );
}
