import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { EquipmentDetailsResponse } from '@connected/data-access/fleet-service';
import { ConnectedActions } from './connected.actions';

export const connectedKey = 'shared-fleet-monitor';

export interface ConnectedState {
  equipmentDetails: EquipmentDetailsResponse | null;
  isEquipmentDetailsLoading: boolean;
  hasEquipmentDetailsFailed: boolean;
  isOperatorMonitor: boolean;
}

export const initialConnectedState: ConnectedState = {
  equipmentDetails: null,
  isEquipmentDetailsLoading: true,
  hasEquipmentDetailsFailed: false,
  isOperatorMonitor: false,
};

const _connectedReducer = createReducer(
  initialConnectedState,
  on(ConnectedActions.LoadEquipmentDetail, (state) => ({
    ...state,
    equipmentDetails: initialConnectedState.equipmentDetails,
    isEquipmentDetailsLoading: true,
    hasEquipmentDetailsFailed: false,
  })),
  on(ConnectedActions.LoadEquipmentDetailSuccess, (state, { payload }) => ({
    ...state,
    equipmentDetails: payload,
    isEquipmentDetailsLoading: false,
    hasEquipmentDetailsFailed: false,
  })),
  on(ConnectedActions.LoadEquipmentDetailError, (state) => ({
    ...state,
    isEquipmentDetailsLoading: false,
    hasEquipmentDetailsFailed: true,
  })),
  on(ConnectedActions.SetIsOperatorMonitor, (state, { isOperatorMonitor }) => ({
    ...state,
    isOperatorMonitor,
  })),
);

export const ConnectedFeature = createFeature({
  name: connectedKey,
  reducer: _connectedReducer,
  extraSelectors: ({ selectEquipmentDetails }) => ({
    selectServicePartnerInfo: createSelector(
      selectEquipmentDetails,
      (equipmentDetails) => equipmentDetails?.service_partner_info,
    ),
  }),
});
