import { createFeature, createReducer, on } from '@ngrx/store';
import { StatusCodesActions } from './status-codes.actions';
import {
  AlertResponse,
  GetAlertCountCommandResult,
  GetAlertsStatisticsPerEquipmentNumberCommandResult,
  GetHistoryAlertsPerEquipmentCommandResult,
} from '@connected/fleet-service/models';
import { Sort } from '@angular/material/sort';
import { StatusCodesFilterSetup } from '../models';
import { mapPPI2PPG } from '@connected/shared/methods';

export const statusCodesKey = 'status-codes';
export interface StatusCodesState {
  dashboardStatusCodes: GetAlertCountCommandResult | null;
  isDashboardStatusCodeLoading: boolean;
  hasDashboardAStatusCodeFailed: boolean;

  history: GetHistoryAlertsPerEquipmentCommandResult;
  isHistoryLoading: boolean;
  hasHistoryFailed: boolean;

  statistics: GetAlertsStatisticsPerEquipmentNumberCommandResult;
  isStatisticsLoading: boolean;
  hasStatisticsFailed: boolean;

  overview: AlertResponse[];
  errorCount: number;
  privateErrorCount: number;
  warningCount: number;
  isOverviewLoading: boolean;
  hasOverviewFailed: boolean;
  filterSetup: StatusCodesFilterSetup;
  sort: Sort;
}

export const initialState: StatusCodesState = {
  dashboardStatusCodes: null,
  isDashboardStatusCodeLoading: true,
  hasDashboardAStatusCodeFailed: false,

  history: {},
  isHistoryLoading: true,
  hasHistoryFailed: false,

  statistics: {},
  isStatisticsLoading: true,
  hasStatisticsFailed: false,

  overview: [],
  errorCount: 0,
  privateErrorCount: 0,
  warningCount: 0,
  isOverviewLoading: true,
  hasOverviewFailed: false,
  filterSetup: {
    text: '',
    carrier: null,
    alert_type: null,
    code: null,
    equipment: null,
    equipment_number: null,
    is_private: null,
  },
  sort: { active: 'created_at', direction: 'desc' },
};

const _statusCodesReducer = createReducer(
  initialState,
  on(StatusCodesActions.LoadDashboardStatusCodes, (state) => ({
    ...state,
    dashboardStatusCodes: initialState.dashboardStatusCodes,
    isDashboardStatusCodeLoading: true,
    hasDashboardAStatusCodeFailed: false,
  })),
  on(
    StatusCodesActions.LoadDashboardStatusCodesSuccess,
    (state, { payload }) => ({
      ...state,
      dashboardStatusCodes: payload,
      isDashboardStatusCodeLoading: false,
      hasDashboardAStatusCodeFailed: false,
    }),
  ),
  on(StatusCodesActions.LoadDashboardStatusCodesError, (state) => ({
    ...state,
    isDashboardStatusCodeLoading: false,
    hasDashboardAStatusCodeFailed: true,
  })),

  on(
    StatusCodesActions.LoadHistory,
    StatusCodesActions.ClearHistory,
    (state) => ({
      ...state,
      history: initialState.history,
      isHistoryLoading: true,
      hasHistoryFailed: false,
    }),
  ),
  on(StatusCodesActions.LoadHistorySuccess, (state, { payload }) => ({
    ...state,
    history: payload,
    isHistoryLoading: false,
    hasHistoryFailed: false,
  })),
  on(StatusCodesActions.LoadHistoryError, (state) => ({
    ...state,
    isHistoryLoading: false,
    hasHistoryFailed: true,
  })),

  on(
    StatusCodesActions.LoadStatistics,
    StatusCodesActions.ClearStatistics,
    (state) => ({
      ...state,
      statistics: initialState.statistics,
      isStatisticsLoading: true,
      hasStatisticsFailed: false,
    }),
  ),
  on(StatusCodesActions.LoadStatisticsSuccess, (state, { payload }) => ({
    ...state,
    statistics: payload,
    isStatisticsLoading: false,
    hasStatisticsFailed: false,
  })),
  on(StatusCodesActions.LoadStatisticsError, (state) => ({
    ...state,
    isStatisticsLoading: false,
    hasStatisticsFailed: true,
  })),

  on(StatusCodesActions.LoadOverview, (state) => ({
    ...state,
    isOverviewLoading: state.overview === initialState.overview,
    hasOverviewFailed: false,
  })),
  on(StatusCodesActions.LoadOverviewSuccess, (state, { payload }) => ({
    ...state,
    overview:
      payload.alerts?.map((item) => mapPPI2PPG(item, 'equipment_type_icon')) ||
      [],
    errorCount: payload.sum_of_errors,
    privateErrorCount: payload.sum_of_private_errors_and_warnings || 0,
    warningCount: payload.sum_of_warnings,
    isOverviewLoading: false,
    hasOverviewFailed: false,
  })),
  on(StatusCodesActions.LoadOverviewError, (state) => ({
    ...state,
    isOverviewLoading: false,
    hasOverviewFailed: true,
  })),
  on(StatusCodesActions.SetFilter, (state, { payload }) => ({
    ...state,
    filterSetup: payload,
  })),
  on(StatusCodesActions.saveSort, (state, { payload }) => ({
    ...state,
    sort: payload,
  })),
);

export const StatusCodesFeature = createFeature({
  name: statusCodesKey,
  reducer: _statusCodesReducer,
});
