import { createFeature, createReducer, on } from '@ngrx/store';
import { FeatureFlagActions } from './feature-flag.actions';

export const featureFlagKey = 'featureFlag';

export interface FeatureFlagState {
  isInfoMessageShown: boolean;
  isSignalREnabled: boolean;
}

export const initialFeatureFlagState: FeatureFlagState = {
  isInfoMessageShown: false,
  isSignalREnabled: false,
};

const _featureFlag = createReducer(
  initialFeatureFlagState,

  on(FeatureFlagActions.loadShowMessageSuccess, (state, { payload }) => ({
    ...state,
    isInfoMessageShown: payload,
  })),
  on(FeatureFlagActions.loadShowMessageError, (state) => ({
    ...state,
    isInfoMessageShown: false,
  })),
  on(FeatureFlagActions.loadSignalRSuccess, (state, { payload }) => ({
    ...state,
    isSignalREnabled: payload,
  })),
  on(FeatureFlagActions.loadSignalRError, (state) => ({
    ...state,
    isSignalREnabled: false,
  })),
);

export const FeatureFlagFeature = createFeature({
  name: featureFlagKey,
  reducer: _featureFlag,
});
